<template>
  <section
    id="check"
    class=""
    style="background-color: ; height: 100vh"
  >
    <div class="container">
      <div
        v-if="userId == null"
        class="text-center"
      >
        <h4 class="pt-2 pb-2">
          Untuk Melanjutkan silahkan Masuk dulu menggunakan Google
        </h4>
        <base-btn
          style="width:100%"
          @click="handleClickSignIn"
        >
          Sign In With Google
        </base-btn>
      </div>

      <div v-else>
        <div
          v-if="isReportSuccess"
          class="text-center"
        >
          <base-img
            :src="require(`@/assets/success.gif`)"
            max-width="400"
          />
          <h4>Terima Kasih, Laporan anda telah kami terima</h4>
          <router-link to="/">
            <base-btn
              class="mt-2"
              style="width:95%"
              v-bind="attrs"
              v-on="on"
            >
              Kembali
            </base-btn>
          </router-link>
        </div>
        <div v-else>
          <loading
            :active.sync="isLoad"
            :can-cancel="true"
            :on-cancel="onCancel"
            :is-full-page="fullPage"
          />

          <div v-if="isWait == false">
            <div
              v-if="isValid"
              class="text-center pt-3 mb-3"
            >
              <base-img
                :src="require(`@/assets/success.gif`)"
                max-width="400"
              />
              <h4>Selamat, anda berhasil menukarkan poin.<br> Poin anda saat ini adalah {{ this.point }}</h4>
              <div class="mt-3" />
              <router-link to="/point">
                <v-btn
                  color="success"
                >
                  Lihat Point Saya
                </v-btn>
              </router-link>
            </div>
            <div
              v-else
              class="text-center pt-3 mb-3"
            >
              <base-img
                :src="require(`@/assets/error.gif`)"
                max-width="400"
              />
              <h3>Oops, kode yang anda masukan tidak ditemukan atau sudah digunakan, silahkan gunakan kode lain atau laporkan pemalsuan</h3>
              <div class="text-center mt-2">
                <v-dialog
                  v-model="dialog"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <base-btn
                      class="mt-2"
                      style="width:95%"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Laporkan!
                    </base-btn>
                  </template>

                  <v-card>
                    <v-card-title class="headline grey lighten-2">
                      <h5>Laporkan Produk Palsu</h5>
                    </v-card-title>

                    <v-card-text class="pt-5">
                      <v-text-field
                        v-model="dataReport.name"
                        label="Nama Anda (opsional)"
                        outlined
                      />
                      <v-text-field
                        v-model="dataReport.contact"
                        label="Email/No HP (opsional)"
                        outlined
                      />
                      <v-text-field
                        v-model="dataReport.product_name"
                        label="Nama Produk"
                        outlined
                      />
                      <v-text-field
                        v-model="dataReport.location"
                        label="Lokasi Pembelian (Nama toko/daerah/dsb)"
                        outlined
                      />
                      <!-- <v-file-input
                                            label="Foto Produk (opsional)"
                                            outlined
                                            dense
                                        ></v-file-input> -->
                    </v-card-text>

                    <v-divider />

                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="primary"
                        @click="postReportData"
                      >
                        Laporkan!
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import Loading from 'vue-loading-overlay'
  import 'vue-loading-overlay/dist/vue-loading.css'
  import { checkingService } from '@/services'
  import axios from 'axios'

  export default {
    name: 'SectionReward',
    provide: {
      theme: { isDark: true },
    },
    components: {
      Loading,
    },

    data () {
      return {
        isValid: undefined,
        isLoad: true,
        isWait: true,
        isUsed: undefined,
        isReportSuccess: false,
        isInit: false,
        isSignIn: false,
        userId: null,
        dataPost: {
          code: '',
          user_id: '',
          location: {
            lat: '',
            lng: '',
            address: '',
            city: '',
            province: '',
            country: '',
          },
        },
        dataReport: {
          name: '',
          contact: '',
          product_name: '',
          location: '',
          qrcode: '',
          stamp_location: {
            lat: '',
            lng: '',
            address: '',
            city: '',
            province: '',
            country: '',
          },
        },
        qrgroup: {},
        product: {},
        point: 0,
        message: null,
      }
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },

    created () {
      const that = this
      const checkGauthLoad = setInterval(function () {
        that.isInit = that.$gAuth.isInit
        that.isSignIn = that.$gAuth.isAuthorized
        if (that.isInit) clearInterval(checkGauthLoad)
      }, 1000)
    },
    mounted: function () {
      this.dataPost.code = this.$route.query.code
      this.dataReport.qrcode = this.$route.query.code
      this.userId = localStorage.userId

      if (this.userId != null) {
        this.dataPost.user_id = this.userId
        this.getLocation()
      }
      this.postData()
    },
    methods: {
      getLocation: function () {
        navigator.geolocation.getCurrentPosition(
          position => {
            this.dataPost.location.lat = position.coords.latitude
            this.dataPost.location.lng = position.coords.longitude

            this.dataReport.stamp_location.lat = position.coords.latitude
            this.dataReport.stamp_location.lng = position.coords.longitude
            this.getStreetAddressFrom(position.coords.latitude, position.coords.longitude)
          },
          error => {
            console.log(error.message)
          },
        )
      },

      async getStreetAddressFrom (lat, long) {
        try {
          var { data } = await axios.get(
            'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
              lat +
              ',' +
              long +
              '&key=AIzaSyD-aYZE3DLSsnf0OPxfxfZ8--AscfkzVdk',
          )
          if (data.error_message) {
            console.log(data.error_message)
          } else {
            this.dataPost.location.city = data.results[0].address_components[4].long_name
            this.dataPost.location.province = data.results[0].address_components[5].long_name
            this.dataPost.location.country = data.results[0].address_components[6].long_name
            this.dataPost.location.address = data.results[0].formatted_address

            this.dataReport.stamp_location.city = data.results[0].address_components[4].long_name
            this.dataReport.stamp_location.province = data.results[0].address_components[5].long_name
            this.dataReport.stamp_location.country = data.results[0].address_components[6].long_name
            this.dataReport.stamp_location.address = data.results[0].formatted_address
          }
        } catch (error) {
          console.log(error.message)
        }

        this.postData()
      },

      postData: function () {
        // checkingService
        // .checkPre(this.dataPost)
        // .then(response => {
        //     this.isValid = response.data.isValid;
        //     this.qrgroup = response.data;
        //     this.product = response.data.product;
        // })
        // .finally(() => {
        //     this.isLoad = false
        // });

        const token = 'VmhamTvYonYAjgt2Nf42'

        axios.post('https://backoffice.fitsea.co.id/api/qr/reward/check', this.dataPost).then(response => {
          // axios.post('http://localhost:8000/api/qr/reward/check', this.dataPost).then(response  => {
          this.isValid = response.data.success
          this.isUsed = response.data.isUsed
          this.point = response.data.newPoint
        })
          .finally(() => {
            this.isLoad = false
            this.isWait = false
            this.message = response.data.message
          })
      },

      postReportData: function () {
        this.dialog = false
        this.isLoad = true
        // axios.post('http://localhost:8000/api/qr/reward/report', this.dataReport).then(response  => {
        axios.post('https://backoffice.fitsea.co.id/api/qr/reward/report', this.dataReport).then(response => {
          this.isReportSuccess = response.data.success
        })
          .finally(() => {
            this.isLoad = false
          })
      },

      async handleClickUpdateScope () {
        const option = new window.gapi.auth2.SigninOptionsBuilder()
        option.setScope('email https://www.googleapis.com/auth/drive.file')
        const googleUser = this.$gAuth.GoogleAuth.currentUser.get()
        try {
          await googleUser.grant(option)
          console.log('success')
        } catch (error) {
          console.error(error)
        }
      },
      handleClickLogin () {
        this.$gAuth
          .getAuthCode()
          .then((authCode) => {
            // on success
            console.log('authCode', authCode)
          })
          .catch((error) => {
            console.error(error)
          })
      },
      async handleClickSignIn () {
        try {
          const googleUser = await this.$gAuth.signIn()
          if (!googleUser) {
            return null
          }

          this.authToBackend(googleUser.getAuthResponse().access_token)
          this.isSignIn = this.$gAuth.isAuthorized
        } catch (error) {
          // on fail do something
          console.error(error)
          return null
        }
      },
      async handleClickSignOut () {
        try {
          await this.$gAuth.signOut()
          this.isSignIn = this.$gAuth.isAuthorized
          console.log('isSignIn', this.$gAuth.isAuthorized)
          localStorage.clear()
        } catch (error) {
          console.error(error)
        }
      },
      handleClickDisconnect () {
        window.location.href = `https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=${window.location.href}`
      },
      authToBackend (accessToken) {
        // axios.post('http://localhost:8000/api/google/callback', {
        axios.post('https://backoffice.fitsea.co.id/api/google/callback', {
          accessToken: accessToken,
        })
          .then(response => {
            if (response.data.valid == true) {
              this.saveToLocal(response.data)
            }
          })

        // handle QR Reward
        this.getLocation()
      },
      saveToLocal (data) {
        localStorage.userId = data.userId
        localStorage.token = data.token
      },

    },
  }
</script>

<style scoped>

</style>
